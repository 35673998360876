<template>
  <loading v-if="!hasSiteData" />
  <div v-else>
    <site-user-role :site-id="siteId" />
    <site-header :site-id="siteId" />
    <site-subscription v-if="!isResellerClient" :site-id="siteId" />
    <site-sub-navigation :site-id="siteId" />
    <ribbon class="has-inset-shadow-top">
      <component :is="overrideComponent" v-if="overrideComponent" />
      <router-view v-else />
    </ribbon>
  </div>
</template>

<script>
export default {
  name: "Site",
  components: {
    "site-user-role": () => import("./_siteUserRole"),
    "site-header": () => import("./_siteHeader"),
    "site-subscription": () => import("./_siteSubscription"),
    "site-sub-navigation": () => import("./_siteSubNavigation")
  },
  data() {
    return {
      siteId: this.$route.params.siteId,
      statusModal: null,
      unavailableModal: null
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    },
    reseller() {
      if (!this.isResellerUser) return null;
      return this.$store.getters["reseller/reseller"](this.user.resellerId);
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    hasSiteData() {
      return this.$_.has(this.site, "dateCreated");
    },
    siteUserRole() {
      return this.$store.getters["sites/site/userRole"](this.siteId);
    },
    isResellerClient() {
      return this.$store.getters["user/isResellerUser"]();
    },
    overrideComponent() {
      const matchedRoute = this.$route.matched[this.$route.matched.length - 1];
      const component = matchedRoute.components["override"];
      return component;
    }
  },
  watch: {
    site() {
      if (!this.site) this.siteNotFound();
    },
    "site.isDeleted"() {
      this.checkSiteStatus();
    },
    "site.isSubscribed"() {
      this.checkIfSiteAvailable();
    }
  },
  created() {
    if (this.site) {
      this.checkSiteStatus();
      this.checkIfSiteAvailable();
    }
    this.$store
      .dispatch("sites/observeSite", { siteId: this.siteId })
      .catch(() => {
        this.siteNotFound();
      });
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  },
  beforeDestroy() {
    if (this.statusModal) this.statusModal.close();
    if (this.unavailableModal) this.unavailableModal.close();
    if (!this.site) return;
    this.$store.dispatch("sites/unobserveSite", { siteId: this.siteId });
  },
  methods: {
    checkIfSiteAvailable() {
      if (
        this.isResellerUser &&
        !this.site.isSubscribed &&
        !this.unavailableModal
      ) {
        this.unavailableModal = this.$modal.open({
          component: () => import("./_siteUnavailableModal"),
          canCancel: [],
          parent: this,
          width: 460,
          props: {
            site: this.site,
            email: this.$_.get(this.reseller, "email", null)
          }
        });
      }

      if (this.site.isSubscribed && this.unavailableModal) {
        this.unavailableModal.close();
        this.unavailableModal = null;
      }
    },
    checkSiteStatus() {
      if (this.site.isDeleted && !this.statusModal) {
        this.statusModal = this.$modal.open({
          component: () => import("./_siteStatusModal"),
          canCancel: [],
          parent: this,
          width: 460,
          props: {
            site: this.site
          },
          events: {
            close: () => {
              this.statusModal = null;
            }
          }
        });
      }
      if (!this.site.isDeleted && this.statusModal) {
        this.statusModal.close();
        this.statusModal = null;
      }
    },
    siteNotFound() {
      this.$router.push(`/sites`);
      this.$toast.open({
        message: `Site not found`,
        position: "is-bottom",
        queue: false,
        type: "is-danger"
      });
    }
  }
};
</script>
